.DCookieConsent-container {
    align-items: baseline;
    background: rgb(43, 55, 59);
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0px;
    position: fixed;
    width: 100%;
    z-index: 999;
    margin: auto;
    bottom: 0px;
}
.DCookieConsent-container-rigth {
    align-items: baseline;
    background: rgb(43, 55, 59);
    color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: fixed;
    z-index: 999;
    margin: auto;
    bottom: 0px;
    right: 0;
    padding-left: 15px;
}

.DCookieConsent-buttonStyle {
    background: #ffd42d;
    border: 0;
    border-radius: 0px;
    box-shadow: none;
    color: black;
    cursor: pointer;
    flex: 0 0 auto;
    padding: 5px 10px;
    margin: 15px;
  }

  .DCookieConsent-declineButtonStyle {
    background: #c12a2a;
    border: 0;
    border-radius: 0px;
    box-shadow: none;
    color: #e5e5e5;
    cursor: pointer;
    flex: 0 0 auto;
    padding: 5px 10px;
    margin: 15px;
  }

  .DCookieConsent-contentStyle {
    flex: 1 0 300px;
    margin: 15px;
  }
  
  .DCookieConsent-overlayStyle {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0,0,0,0.3);
  }