.button-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;


}


@media (max-width: 995px) {
    .button-group {
        width: 100%;
        flex-direction: column;
    }
}